<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <q-chip
            v-if="(!preStartupCheck.equipments || preStartupCheck.equipments.length === 0) && Boolean(param.sopPrestartupCheckId)"
            class="no-border"
            style="margin-right:5px !important" 
            square outline
            color="red" 
            text-color="white" 
            icon="report" 
            size="12px" >
            가동전점검표, 보고서는 하나 이상의 설비 점검표가 만들어져야 이용 가능합니다.
          </q-chip> -->
          <!-- <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="점검표" 
            icon="print"
            @btnClicked="checklistPrint" />
          <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="점검보고서" 
            icon="print"
            @btnClicked="checklistReportPrint" /> -->
          <!-- <c-btn 
            v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" 
            :isSubmit="isComplete"
            :url="saveUrl"
            :param="preStartupCheck"
            mappingType="PUT"
            label="점검완료" 
            icon="check"
            @beforeAction="completePreStartupCheck"
            @btnCallback="completeCallback" />
            <br/>
          <c-btn 
            v-show="editInfo && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="preStartupCheck"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="savePreStartupCheck"
            @btnCallback="saveCallback" />
          <c-btn v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" label="삭제" icon="remove" @btnClicked="removePreStartupCheck" /> -->
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <c-text
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                label="점검명"
                name="checkName"
                v-model="preStartupCheck.checkName">
              </c-text>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
              <c-select
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                codeGroupCd="PSR_EQUIPMENT_STATUS_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="psrEquipmentStatusCd"
                label="설비상태"
                v-model="preStartupCheck.psrEquipmentStatusCd"
              ></c-select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
              <c-plant 
                :required="true" 
                :disabled="disabled"
                :editable="editInfo" 
                type="edit" 
                name="plantCd" 
                v-model="preStartupCheck.plantCd" />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
              <c-dept 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                type="edit" 
                label="점검부서" 
                name="checkDeptCd" 
                v-model="preStartupCheck.checkDeptCd" />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
              <c-field 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                :plantCd="preStartupCheck.plantCd"
                type="user" 
                label="점검총책임자" 
                name="checkCancarrierId" 
                v-model="preStartupCheck.checkCancarrierId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <c-textarea
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                label="점검목적"
                name="checkPurpose"
                v-model="preStartupCheck.checkPurpose">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
              <c-moc 
                :editable="editInfo"
                :disabled="disabled"
                :isSubmit="saveCallData"
                :document.sync="preStartupCheck"
                :documentId="param.sopPrestartupCheckId"
                documentTitle="checkName"
                mocRelatedTaskCd="RT00000015"
                label="MOC번호"
                name="sopMocId"
                v-model="preStartupCheck.sopMocId"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="row">
            <div class="col-12">
              <c-textarea
                :editable="editInfo"
                :disabled="disabled"
                :rows="3"
                label="점검결과종합"
                name="checkResultTotal"
                v-model="preStartupCheck.checkResultTotal">
              </c-textarea>
            </div>
          </div>
        </div>
      </template>
    </c-card>
    <c-tab
      class="q-mt-sm preStartup-tab-no-padding"
      type="tabcard"
      align="left"
      :editable="editInfo"
      :tabItems.sync="preStartupCheck.equipments"
      :inlineLabel="true"
      :addTab="editInfo && !disabled"
      :removeTab="editInfo && !disabled"
      removeConditionCol="checkCompleteFlag"
      removeConditionVal="Y"
      v-model="tab"
      @addTab="addTab"
      @removeTab="removeTab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :preStartupCheck.sync="preStartupCheck"
          :equipmentCd="tab.equipmentCd"
          :research.sync="research"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <!-- <q-chip
            v-if="(!preStartupCheck.equipments || preStartupCheck.equipments.length === 0) && Boolean(param.sopPrestartupCheckId)"
            class="no-border"
            style="margin-right:5px !important" 
            square outline
            color="red" 
            text-color="white" 
            icon="report" 
            size="12px" >
            가동전점검표, 보고서는 하나 이상의 설비 점검표가 만들어져야 이용 가능합니다.
          </q-chip> -->
          <!-- <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="점검표" 
            icon="print"
            @btnClicked="checklistPrint" />
          <c-btn 
            v-show="Boolean(param.sopPrestartupCheckId)" 
            :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
            label="점검보고서" 
            icon="print"
            @btnClicked="checklistReportPrint" /> -->
          <c-btn 
            :flat="true"
            v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" 
            :isSubmit="isComplete"
            :url="saveUrl"
            :param="preStartupCheck"
            mappingType="PUT"
            label="점검완료" 
            icon="check"
            @beforeAction="completePreStartupCheck"
            @btnCallback="completeCallback" />
            <br/>
          <c-btn 
            :flat="true"
            v-show="editInfo && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="preStartupCheck"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="savePreStartupCheck"
            @btnCallback="saveCallback" />
          <c-btn :flat="true" v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" label="삭제" icon="remove" @btnClicked="removePreStartupCheck" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-review-detail',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    preStartupCheck: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      editable: true,
      detailUrl: '',
      equipListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      checklistPrintUrl: '',
      checklistReportPrintUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveCallData: '',
    };
  },
  watch: {
    'research.item'() {
      this.getEquips();
    }
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.preStartupCheck.psrCheckStatusCd === 'PCSC000005';
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.psr.check.get.url;
      this.equipListUrl = selectConfig.sop.psr.check.equips.url;
      this.saveUrl = transactionConfig.sop.psr.check.insert.url
      this.deleteUrl = transactionConfig.sop.psr.check.delete.url
      this.checklistPrintUrl = selectConfig.sop.psr.check.checklistPrint.url
      this.checklistReportPrintUrl = selectConfig.sop.psr.check.checklistReportPrint.url
      // code setting
      // list setting
      this.getPreStartupCheck();
    },
    getPreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopPrestartupCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preStartupCheck, _result.data)
          this.param.psrCheckStatusCd = this.$_.clone(this.preStartupCheck.psrCheckStatusCd)
          // this.$_.extend(this.preStartupCheck, _result.data);

          if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
            this.tab = this.preStartupCheck.equipments[0].equipmentCd;
            this.$_.forEach(this.preStartupCheck.equipments, item => {
              item.name = item.equipmentCd;
              item.label = item.equipmentName;
              item.icon = 'precision_manufacturing'
              item.component = () => import(`${'./preStartupFacility.vue'}`)
              item.key = uid()
              // this.tabItems.push({ name: item.equipmentCd, icon: 'precision_manufacturing', label: item.equipmentName, component: () => import(`${'./preStartupFacility.vue'}`) })
            })
          }

        },);
      }
    },
    getEquips() {
      this.$http.url = this.equipListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopPrestartupCheckId: this.param.sopPrestartupCheckId,
      };
      this.$http.request((_result) => {
        this.tab = _result.data[0].equipmentCd;
        this.$_.forEach(_result.data, item => {
          item.name = item.equipmentCd;
          item.label = item.equipmentName;
          item.icon = 'precision_manufacturing'
          item.component = () => import(`${'./preStartupFacility.vue'}`)
          item.key = uid()
        })
        this.$set(this.preStartupCheck, 'equipments', _result.data)
      },);
    },
    addTab() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.isFull = false;
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
          this.tab = data[0].equipmentCd;
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.preStartupCheck.equipments, { name: item.equipmentCd }) === -1) {
            this.preStartupCheck.equipments.push({
              name: item.equipmentCd,
              label: item.equipmentName,
              icon: 'precision_manufacturing',
              component: () => import(`${'./preStartupFacility.vue'}`),
              sopPrestartupCheckId: '',  // 가동전점검 일련 번호
              equipmentCd: item.equipmentCd,  // 설비코드
              equipmentName: item.equipmentName,  // 설비명
              equipmentTypeCd: item.equipmentTypeCd, // 설비 분류(상하위구조)
              equipmentTypeName: item.equipmentTypeName, // 설비 분류명(상하위구조)
              checkUserId: '',  // 점검 책임자
              checkScheduleDate: '',  // 점검 예정일
              checkDate: '',  // 점검일
              checkResultTotal: '',  // 점검 결과 종합
              checkCompleteFlag: 'N',  // 점검 완료 여부
              regUserId: '',  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              checkTypes: [], // 점검유형
              checkItems: [], // 점검항목
            })
          }
        })
      }
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '제외하실 경우 해당 공정에 해당하는\n\r점검정보 및 결과목록도 변경됩니다.\n\r진행하시겠습니까? ',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$set(this.preStartupCheck.equipments, this.$_.findIndex(this.preStartupCheck.equipments, tab), null)
          if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
            this.tab = this.preStartupCheck.equipments[0].name
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick(tab) {
      this.tab = tab.name;
    },
    savePreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.saveUrl = transactionConfig.sop.psr.check.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.psr.check.insert.url
        this.mappingType = 'POST';
        this.preStartupCheck.psrCheckStatusCd = 'PCSC000001' // 점검중
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preStartupCheck.regUserId = this.$store.getters.user.userId
              this.preStartupCheck.chgUserId = this.$store.getters.user.userId

              if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
    completePreStartupCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (!this.preStartupCheck.checkResultTotal) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '점검결과종합가 기입되지 않았습니다.',
              type: 'warning', // success / info / warning / error
            });
          } else if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '하나 이상의 점검표가 만들어져야 합니다.',
              type: 'warning', // success / info / warning / error
            });
          } else {
            let isEquipComplete = true;
            let isImprComplete = true;
            let message = '점검완료하시겠습니까?';
            this.$_.forEach(this.preStartupCheck.equipments, equip => {
              if (equip.checkCompleteFlag !== 'Y') {
                isEquipComplete = false
                return false;
              }

              this.$_.forEach(equip.checkItems, checkItem => {
                if (this.$_.findIndex(checkItem.improves, impr => {
                  return impr.ibmStepCd !== 'IS00000020'
                }) > -1) {
                  isImprComplete = false;
                  return false;
                }
              })
              if (!isImprComplete) {
                return false;
              }
            })

            if (!isEquipComplete) {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '점검이 완료되지 않은 설비가 있습니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            if (!isImprComplete) {
              message = '개선 진행중인 항목이 존재합니다.\n\r점검완료하시겠습니까?'
            }
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: message,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.preStartupCheck.chgUserId = this.$store.getters.user.userId
                this.preStartupCheck.psrCheckStatusCd = 'PCSC000005'
  
                if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                  this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
                }
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
    removePreStartupCheck() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.sopPrestartupCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    checklistPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '가동전점검표_' + this.preStartupCheck.checkName + '_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    checklistReportPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistReportPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '가동전 안전점검 보고서_' + this.preStartupCheck.checkName + '_'  + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
<style lang="sass">
.preStartup-tab-no-padding
  .q-tab-panel
    padding: 3px !important
    .q-table__container
      margin: 0px !important
</style>